exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-iasis-certified-providers-co-js": () => import("./../../../src/pages/iasis_certified_providers_co.js" /* webpackChunkName: "component---src-pages-iasis-certified-providers-co-js" */),
  "component---src-pages-iasis-mcn-clinical-studies-js": () => import("./../../../src/pages/iasis_mcn_clinical_studies.js" /* webpackChunkName: "component---src-pages-iasis-mcn-clinical-studies-js" */),
  "component---src-pages-iasis-mcn-conditions-addiction-js": () => import("./../../../src/pages/iasis_mcn_conditions/addiction.js" /* webpackChunkName: "component---src-pages-iasis-mcn-conditions-addiction-js" */),
  "component---src-pages-iasis-mcn-conditions-adhd-add-js": () => import("./../../../src/pages/iasis_mcn_conditions/adhd-add.js" /* webpackChunkName: "component---src-pages-iasis-mcn-conditions-adhd-add-js" */),
  "component---src-pages-iasis-mcn-conditions-anger-js": () => import("./../../../src/pages/iasis_mcn_conditions/anger.js" /* webpackChunkName: "component---src-pages-iasis-mcn-conditions-anger-js" */),
  "component---src-pages-iasis-mcn-conditions-anxiety-js": () => import("./../../../src/pages/iasis_mcn_conditions/anxiety.js" /* webpackChunkName: "component---src-pages-iasis-mcn-conditions-anxiety-js" */),
  "component---src-pages-iasis-mcn-conditions-index-js": () => import("./../../../src/pages/iasis_mcn_conditions/index.js" /* webpackChunkName: "component---src-pages-iasis-mcn-conditions-index-js" */),
  "component---src-pages-iasis-mcn-treatments-js": () => import("./../../../src/pages/iasis_mcn_treatments.js" /* webpackChunkName: "component---src-pages-iasis-mcn-treatments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mcn-process-js": () => import("./../../../src/pages/mcn_process.js" /* webpackChunkName: "component---src-pages-mcn-process-js" */),
  "component---src-pages-neurofeedback-therapy-offers-js": () => import("./../../../src/pages/neurofeedback-therapy-offers.js" /* webpackChunkName: "component---src-pages-neurofeedback-therapy-offers-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

